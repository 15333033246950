import toast from "react-hot-toast";
import {
  getHomepageDetailsAfterLogin,
  getProfile,
  getWalletData,
  gluedinLogin,
} from "../../services";
import {
  setClaimFormWindowStatus,
  setHomePageDetails,
  setRewardDetails,
} from "../../store/slices/common-slice";
import {
  getCDSPixelId,
  getlanguageId,
  isClaimFormWindowOpen,
  isTamil,
} from "../helpers";
import Cookies from "js-cookie";
import { setUser } from "../../store/slices/auth-slice";
let currentLanguage = Cookies.get("language");
/**
 * @method apiErrorHandler
 * @description handle api error
 */
export const apiErrorHandler = (res) => {
  if (res && res.message) {
    toast.error(res.message);
  } else {
    toast.error("Something went wrong please try again later");
  }
};

//get all home page after login
export const getAllHomepageDetailsALogin = async (dispatch, toggleLoader) => {
  toggleLoader(true);
  try {
    let reqData = { languageId: getlanguageId(currentLanguage) };
    const res = await getHomepageDetailsAfterLogin(reqData);
    if (res.status === 200 && res.data.succeeded) {
      let section = res.data && res.data.data && res.data.data.sections;
      let notificationCount =
        res.data && res.data.data && res.data.data.newNotification;
      let coinCount = res.data && res.data.data && res.data.data.walletCoins;
      const updatedPayload = {
        sections: [...section], // Replace with the actual updated array
        notificationCount: notificationCount,
        coinCount: coinCount,
      };
      dispatch(setHomePageDetails(updatedPayload));
    }
  } catch (err) {
  } finally {
    toggleLoader(false);
  }
};

export const getRewardListAPI = async (dispatch, toggleLoader) => {
  toggleLoader(true);
  const hfSessionKey = await getCDSPixelId();
  const reqData = {
    hfSession: hfSessionKey || "",
    languageId: getlanguageId(currentLanguage),
  };
  try {
    const res = await getWalletData(reqData);
    if (res.status === 200) {
      let rewardList = res.data && res.data.data && res.data.data.rewards;
      dispatch(setRewardDetails(rewardList));
    }
  } catch (err) {
  } finally {
    toggleLoader(false);
  }
};

export const getWalletList = async (toggleLoader, dispatch) => {
  toggleLoader(true);
  const hfSessionKey = await getCDSPixelId();

  const langId = getlanguageId(currentLanguage)
    ? getlanguageId(currentLanguage)
    : null;
  const reqData = {
    hfSession: hfSessionKey || "",
    languageId: langId,
  };
  try {
    const res = await getWalletData(reqData);

    if (res.status === 200) {
      let rewardList = res.data && res.data.data;
      // &&
      // res.data.data.rewards &&
      // Array.isArray(res.data.data.rewards) &&
      // res.data.data.rewards.length
      //   ? res.data.data.rewards
      //   : '';
      dispatch(setRewardDetails(rewardList));
      let claimableRewardId = null;
      for (const reward of rewardList) {
        if (reward.canClaim) {
          claimableRewardId = reward && reward?.rewardsId;
          claimableRewardId &&
            isClaimFormWindowOpen(claimableRewardId, langId).then((result) => {
              dispatch(setClaimFormWindowStatus(result));
            });
          break; // Exit the loop once a claimable reward is found
        }
      }
    }
  } catch (err) {
  } finally {
    toggleLoader(false);
  }
};

export const getUserDetails = async (userDetails, dispatch, toggleLoader) => {
  toggleLoader(true);
  dispatch(setUser(null));
  try {
    let reqData = {
      userId: userDetails && userDetails.id,
    };
    const res = await getProfile(reqData);
    if (res.status === 200) {
      let data = res.data && res.data;
      dispatch(setUser(data));
      let formData = {
        email: `${userDetails && userDetails.id}@gmail.com`,
        password: userDetails && userDetails.id,
      };
      gluedinLogin(formData);
      getAllHomepageDetailsALogin(dispatch, toggleLoader);
      if (data && data.isprofileComplete) {
        // ga event fire ones profile is successfully completed
        if (isTamil()) {
          window.dataLayer.push({ event: "CSTamil24_CompletedProfile" });
        } else {
          window.dataLayer.push({ event: "CSBharat24_CompletedProfile" });
        }
      }
    }
  } catch (err) {
  } finally {
    toggleLoader(false);
  }
};
