import "./styles/sass/style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useRef } from "react";
import PublicLayout from "./components/layouts/Publiclayout";
import { LoaderProvider } from "./utils/context";
import { PopupProvider } from "./components/common/context/popup-context";
import { Toaster } from "react-hot-toast";
import { ViewportProvider } from "./utils/context/viewport-context";
import gluedin from "gluedin";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  setCoinAnimation,
  setIpDetails,
  showTourGuide,
} from "./store/slices/common-slice";
import { useLocation } from "react-router";
import Cookies from "js-cookie";
import CoinAnimation from "./components/coinanimation";
import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
// import { messaging, getToken, onMessage } from "./firebase";

function App() {
  Cookies.set("server_url", process.env.REACT_APP_API_BASE_URL);
  const { pathname } = useLocation();
  if(!localStorage.getItem("siteType"))
    {
      if (pathname.includes("tamil")) {
        localStorage.setItem("siteType", "tamil");
      } else if (pathname.includes("bharat")) {
        localStorage.setItem("siteType", "bharat");
      }
    }
  const reCaptchaRef = useRef();
  //added coin animation timer
  const showCoin = useSelector((state) => state.common.showCoin);
  const dispatch = useDispatch();
  let siteType = window.location.pathname.includes("/tamil")
    ? "tamil"
    : "bharat";
  const tourGuide = useSelector((state) => state?.common?.tourGuide);
  
  useEffect(() => {
    if (siteType === "tamil") {
      //debugger
      let gluedInSDKInitilize = new gluedin.GluedInSDKInitilize();
      gluedInSDKInitilize.initialize({
        apiKey: process.env.REACT_APP_API_KEY_TAMIL,
        secretKey: process.env.REACT_APP_SECRET_KEY_TAMIL,
      });
    } else {
      //debugger
      let gluedInSDKInitilize = new gluedin.GluedInSDKInitilize();
      gluedInSDKInitilize.initialize({
        apiKey: process.env.REACT_APP_API_KEY_BHARAT,
        secretKey: process.env.REACT_APP_SECRET_KEY_BHARAT,
      });
    }
  }, [siteType]);

  // fetch ip data
  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const ipResponse = await axios.get(process.env.REACT_APP_GET_IP);
        const ipAddress = ipResponse.data.ip;
        fetchData(ipAddress);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    const fetchData = async (ipAddress) => {
      let ipUrl = `${process.env.REACT_APP_IP_DETAIL}${ipAddress}?key=${process.env.REACT_APP_IP_KEY}`; // dynamic ip
      try {
        const response = await axios.get(ipUrl);
        dispatch(setIpDetails(response.data));
        localStorage.setItem("ipDetails", JSON.stringify(response.data));
        let ipDetail = {
          country: response.data && response.data.country,
          country_code: response.data && response.data.countryCode,
          region: response.data && response.data.regionName,
          region_code: response.data && response.data.region,
          city: response.data && response.data.city,
        }
        window.dataLayer.push({
          ipDetail
        })
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchIPAddress();
    if (showCoin) {
      dispatch(setCoinAnimation(false));
    }
    if (tourGuide) {
      dispatch(showTourGuide({ showTourGuide: false }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // remove all the consoles // comment if you want to see consoles

  return (
    <ViewportProvider>
      <LoaderProvider>
        <PopupProvider>
          {/* <CustomNotification/> */}
          <PublicLayout />
          <CoinAnimation />
          <Toaster
            position="top-center"
            reverseOrder={false}
            toastOptions={{
              className: "",
              style: {
                backgroundColor: "black", // Black background color
                color: "white", // White text color
                border: "1px solid #713200",
                padding: "10px",
              },
              duration: 8000, // Set a duration greater than 0 to remove the close button
              icon: false, // Remove success icon
            }}
          />
          <ReCAPTCHA
            theme="dark"
            size="invisible"
            ref={reCaptchaRef}
            sitekey={"6LfPbIMpAAAAADQIXlmBMuJbFnHGbFmfS2B7Uwmb"}
          />
        </PopupProvider>
      </LoaderProvider>
    </ViewportProvider>
  );
}

export default App;
