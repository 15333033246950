import React from 'react';
import { useViewport } from '../../utils/context/viewport-context';
import {  useSelector } from 'react-redux';

const CoinAnimation = () => {
  const { isMobile } = useViewport();
  const showCoin = useSelector(state => state.common.showCoin);

  return (
    <>
      {showCoin ? (
        isMobile ? (
          <img
            className="coin-animation"
            src="/assets/img/modal/coin_animation_m.gif"
            alt=''
          />
        ) : (
          <img
            className="coin-animation"
            src="/assets/img/modal/coin_animation_d.gif"
            alt=''
          />
        )
      ) : null}
    </>
  );
}

export default CoinAnimation;
