import React from "react";
import { Link, useLocation } from "react-router-dom";
import useTranslation from "../../utils/hooks/use-translation";
import { useViewport } from "../../utils/context/viewport-context";
import { addPathSeparators, CounterFloodLight } from "../../utils/helpers";

function CommonFooter({ footerClass }) {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const location = useLocation();

  // Determine whether to show the footer on desktop for specific pages
  const showFooterOnDesktop = false
    // location.pathname === addPathSeparators("/login") ||
    // location.pathname === addPathSeparators("/otp_verification");

  return (
    <>
      {/* Claim form footer code  */}
      {/* {(isMobile || showFooterOnDesktop) && (
        <div
          className={`row align-items-center mt-4 pt-4 footer-claim-form ${footerClass}`}
        >
          <div className="col-5 mr-0">
            <Link
              onClick={() => {
                CounterFloodLight([
                  "DC-12665261/coca-0/cocac02e+unique",
                  "DC-12665261/coca-0/cocac038+unique",
                ]);
              }}
              to={addPathSeparators("/terms_conditions")}
              className="border-right pr-3 link  "
              style={{
                fontSize:"10px"
              }}
            >
              {t.common.tnc} 
                <sup>*</sup>
            </Link>
            <Link
              onClick={() => {
                CounterFloodLight([
                  "DC-12665261/coca-0/cocac02-+unique",
                  "DC-12665261/coca-0/cocac033+unique",
                ]);
              }}
              to={addPathSeparators("/privacy_policy")}
              className="pl-3 link "
              style={{
                fontSize:"10px"
              }}
            >
              {t.header.privacy_policy}
            </Link>
          </div>
          <div className="col-7 text-right">
            <p className="mb-0 text-nowrap">
              {t.common.footer_heading}{" "}
              <img src="/assets/img/icons/dietary_symbol.svg" alt="" />
            </p>
          </div>
        </div>
      )} */}
    </>
  );
}

export default CommonFooter;
