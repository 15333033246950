import React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NavLinks from "./nav-links";
import useAuth from "../../utils/hooks/use-auth";
import { logoutUser } from "../../store/slices/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { menuData, menuData2 } from "../../utils/constants";
import useTranslation from "../../utils/hooks/use-translation";
import {
  addPathSeparators,
  isTamil,
  CounterFloodLight,
  MetaPixel,
} from "../../utils/helpers";
// import usePopup from "../../utils/hooks/use-popup";
import { setAfterLoginAction } from "../../store/slices/common-slice";

const Sidebar = (props) => {
  const { t } = useTranslation();
  // const { openLogoutPopup } = usePopup();
  let allMenuOptions = menuData(t);
  let menuDataOptions = menuData2(t);
  const { navigateTo } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, userDetails } = useAuth();
  const homePageData = useSelector((state) => state?.common?.homepageDetails);

  let siteType = isTamil() ? "tamil" : "bharat";

  const logoutUserAction = () => {
    localStorage.clear();
    dispatch(logoutUser());
    localStorage.setItem("siteType", siteType);
    window.location.assign(`/${siteType}`);
    dispatch(
      setAfterLoginAction({
        action: "",
        redirectPath: "",
      })
    );
  };

  // const rederLogoutModel = (data) => {
  //   let contentType = {
  //     image: '/assets/img/modal/daily-limit.svg',
  //     heading: 'Leaving so soon?',
  //     subHeading:
  //       'Log in and engage with the platform to win exciting rewards.',
  //     description: 'Are you sure you want to exit?',
  //     confirm: 'Yes',
  //     cancel: 'No',
  //     onAction: logoutUserAction,
  //   };
  //   openLogoutPopup(true, contentType);
  // };
  const redirectToLogin = () => {
    setAfterLoginAction({ action: "", redirectPath: "/" });
    localStorage.setItem("pathname", `/${siteType}/my_profile`);
    navigateTo();
    navigate(addPathSeparators("/login"));
  };

  const redirectToMyProfile = () => {
    navigateTo();
    navigate(addPathSeparators("/my_profile"));
  };

  return (
    <>
      <div className="w-100">
        <Button
          variant={"grey"}
          size="lg"
          style={{ borderRadius: "1rem" }}
          className="btn-icon btn-block"
          onClick={() =>
            isLoggedIn
              ? (redirectToMyProfile(),
                CounterFloodLight([
                  "DC-12665261/coca-0/cocac02a+unique",
                  "DC-12665261/coca-0/cocac034+unique",
                ]))
              : (redirectToLogin(),
                CounterFloodLight([
                  "DC-12665261/coca-0/cocac025+unique",
                  "DC-12665261/coca-0/cocac02_+unique",
                ]),
                MetaPixel("CSBLogin", "CSTLogin"))
          }
        >
          {isLoggedIn ? (
            <>
              <div className="user-card">
                <img
                  className="user-pic"
                  src={
                    userDetails && userDetails.avatarURL
                      ? userDetails.avatarURL
                      : "/assets/img/icons/default-user.svg"
                  }
                  alt=""
                />
                <div className="user-details">
                  <h6
                    className="user-title d-inline-block text-truncate"
                    style={{ width: "118px" }}
                  >
                    {userDetails && userDetails.name}
                  </h6>
                  <p className="user-coin">
                    {t.header.coke_coins}{" "}
                    {homePageData && homePageData.coinCount}
                  </p>
                </div>
              </div>
              <span className="icon icon-right-arrow icon-Right-arrow-01"></span>
            </>
          ) : (
            <>
              <span>{t.header.login}</span>
              <span className="icon icon-right-arrow icon-Right-arrow-01"></span>
            </>
          )}
        </Button>
        <NavLinks data={allMenuOptions} navigateTo={navigateTo} />
      </div>
      <div className="w-100">
        <NavLinks data={menuDataOptions} navigateTo={navigateTo} />
        {isLoggedIn && (
          <Link
            to=""
            className="btn-link btn-link-lg d-inline-block mt-5"
            onClick={() => {
              logoutUserAction();
              CounterFloodLight([
                "DC-12665261/coca-0/cocac026+unique",
                "DC-12665261/coca-0/cocac03+unique",
              ]);
              MetaPixel("CSBLogout", "CSTLogout");
            }}
          >
            {t.header.logout}
          </Link>
        )}
      </div>
    </>
  );
};

export default Sidebar;
