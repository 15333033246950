import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { useDispatch } from "react-redux";

// const dispatch = useDispatch();

const initialState = {
    permission: false,
    // streamData : null,
    recordChunks: null,
    recordedVideo: null,
    recordingStatus: 'InActive',
    recordType: null,
    liveVideoRef: null,
    songMinute: 0,
    songSeconds: 0,
    selectedSong: '',
    recordedAudio: '',
    recording: null
};

export const karaokeSlice = createSlice({
    name: "karaoke",
    initialState,
    reducers: {
        setPermission: (state, action) => {
            const { payload } = action;
            state.permission = payload;
        },
        setRecordChunks: (state, action) => {
            const { payload } = action;
            state.recordChunks = payload;
        },
        setRecordedVideo: (state, action) => {
            const { payload } = action;
            state.recordedVideo = payload;
        },
        setRecordingStatus: (state, action) => {
            const { payload } = action;
            state.recordingStatus = payload;
        },
        setRecordType: (state, action) => {
            const { payload } = action;
            state.recordType = payload;
        },
        reset: (state) => {
            state.permission = initialState.permission;
            state.recordChunks = initialState.recordChunks;
            state.recordedVideo = initialState.recordedVideo;
            state.recordingStatus = initialState.recordingStatus;
        },
        setLiveVideoRef: (state, action) => {
            const { payload } = action;
            state.liveVideoRef = payload;
        },
        setSelectedSong: (state, action) => {
            const { payload } = action;
            state.selectedSong = payload;
        },
        setRecordedAudio: (state, action) => {
            const { payload } = action;
            state.recordedAudio = payload;
        },
        setRecording: (state, actions) => {
            state.recording = actions.payload; // Set the IP details to the payload of the action
        },
    }
});

export const recordTypeThunk = createAsyncThunk(
    "karaoke/setRecordType",
    async (typeRecord, { dispatch }) => {
        dispatch(setRecordType((typeRecord)));
    }
);

export const recordVideoThunk = createAsyncThunk(
    "karaoke/setRecordedVideo",
    async (blobUrl, { dispatch }) => {
        // console
        dispatch(setRecordedVideo(blobUrl));
        dispatch(setRecordChunks([]));
        dispatch(setRecordingStatus('inactive'));
    }
);

export const {
    setPermission,
    // setStreamData,
    setRecordChunks,
    setRecordedVideo,
    setRecordingStatus,
    setRecordType,
    reset,
    setLiveVideoRef,
    setSelectedSong,
    setRecordedAudio,
    setRecording
} = karaokeSlice.actions;

export default karaokeSlice.reducer;