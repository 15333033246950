export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const version = 'v1';
export const IMAGE_BASE_URL =
  'https://s3.ap-south-1.amazonaws.com/017073-cokestudio2.0-dev';
export const LANGUAGEID = 1;
export const rewardType = {
  airpods: 'Airpods',
  iphone: 'iPhone',
  lollapalooza: 'Lollapalooza',
  tomorrowland: 'Tomorrowland',
  csk_vip_tickets: 'TicketsCSKVIPTickets',
  csk_match_tickets: 'CSKMatchTickets',
};

// let typeOfSite = localStorage.getItem('siteType');
// let siteType =
//   window.location.pathname.includes('/tamil') || typeOfSite === 'tamil'
//     ? 'tamil'
//     : 'bharat';

const bharatId = {
  hero_banner: 'd588d751-2673-4c09-b3b8-a1098c656926', // hero banner
  promo_banner: '00efb1ec-4bd3-474c-b317-c418fa13082a', //promo banner
  coke_exclusive: '1ec2e1c9-58a0-4964-be9e-78dbb31bcf9b', //coke studio exclusive
  coke_heads: 'f126fa2e-567c-41b0-8421-a38fef2bb025', //best of coke studio heads
  around_the_world: 'e3d6cd59-c722-48ce-a9d2-0bab5ac24b04', //around the world
  behind_the_scene: 'f126fa2e-567c-41b0-8421-a38fef2bb025', //behind the scene
  melodies_rewind: '24a026e2-6305-4956-bb3b-4ab2115390e8', //melodies rewind,
  artist: '5be40002-ab73-452b-a6dc-58b8bf40beb5', // Artist Data
  contest_promo: 'f37649d4-0acb-4d8b-8d32-ec0565c60fca', // contest promo banner
  ugc_data:'38cac500-a5f6-43ae-a687-00557fa6fc55' // ugc video/audio
};

const tamilId = {
  hero_banner: 'afb3d56f-c6b0-4bcc-bf59-1fb7c71f0cb5', // hero banner
  promo_banner: 'ecb7d099-8d9c-4a75-b52a-4ec0090a748e', // promo banner
  coke_exclusive: '6a3ab215-9bb7-4fd6-90a3-9f2921bdebd9', //coke studio exclusive
  coke_heads: '40314a46-9f3d-4042-b512-667f1f28ce09', //best of coke studio heads
  around_the_world: 'b8b8238f-3026-4016-92c1-a23e8d9be34f', //around the world
  behind_the_scene: '40314a46-9f3d-4042-b512-667f1f28ce09', //behind the scene
  melodies_rewind: '88fc854f-1aeb-4ee8-8f60-46ade3a10235', //melodies rewind,
  artist: 'ea4ca69f-b760-4571-bec0-816e1dd0a99e', // Artist Data
  contest_promo: '6628f243-7fcd-46ac-9b0a-66a7dc84d630', // contest promo banner
  ugc_data:'d37165e0-78c3-43f1-aae1-e966ce88f6c6'// ugc video/audio
};

let siteType = window.location.pathname;
let rails;
if(localStorage.getItem("siteType"))
  {
    if(localStorage.getItem("siteType")==='tamil')
      {
        rails = tamilId
      }else{
        rails = bharatId
      }
  }else{
    if(siteType?.includes('/tamil'))
      {
        rails = tamilId
      }else{
        rails = bharatId
      }
  }
export const RailId = rails