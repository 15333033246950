import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import useLoader from '../../utils/hooks/use-loader';
import {  getAvatarList } from '../../services';
import { useDispatch } from 'react-redux';
import { setAvatarList } from '../../store/slices/common-slice';
import { useViewport } from '../../utils/context/viewport-context';
import useAuth from '../../utils/hooks/use-auth';
import { useParams } from 'react-router-dom';
import { setSiteType } from '../../store/slices/common-slice';
import { addPathSeparators, manageKaraokePath } from '../../utils/helpers';
import { getUserDetails } from '../../utils/axios-helper/api-hander';

function PublicLayout() {
  const { siteType } = useParams();
  const { pathname, hash } = useLocation();
  const { toggleLoader } = useLoader();
  const dispatch = useDispatch();
  const { isMobile } = useViewport();
  const { isLoggedIn, userDetails } = useAuth();
  let pathList = [
    `${addPathSeparators('/login')}`,
    `${addPathSeparators('/signup')}`,
    `${addPathSeparators('/otp_verification')}`,
  ];
  let karaokePathList = [
    manageKaraokePath(),
    // `${addPathSeparators('/KaraokesongList')}`
  ];
  let switchLayout = pathList.includes(pathname);

  const isHeaderFooterNotVisible = [
    //addPathSeparators(`/karaoke_recording`)
    // addPathSeparators("/faq"),
    // addPathSeparators("/terms_conditions"),
    // addPathSeparators("/privacy_policy"),
    // addPathSeparators("/my_profile/1"),
    // addPathSeparators("/see_all/video_card/coke_studio_exclusive"),
    // addPathSeparators("/see_all/video_card/coke_studio_heads"),
    // addPathSeparators("/see_all/video_card/behind_the_scene"),
    // addPathSeparators("/see_all/video_card/melodies_rewind"),
  ].includes(pathname);
  const onlyFooter =
    pathname === addPathSeparators('/artist') ||
    pathname.startsWith(addPathSeparators('/artist_details'));

  // handling all the hash routing
  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const el = document.getElementById(hash?.replace('#', '')?.trim());
        el?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }, 200);
    }
  }, [hash]);

  useEffect(() => {
    if (pathList.includes(pathname)) {
      // Set the className of the body element
      document.body.className = 'login_journey';
      document.body.style.overflow = 'auto';
      // Clean up the className when the component unmounts
      return () => {
        document.body.className = '';
      };
    }
    // if (karaokePathList.includes(pathname)) {
    //   // Set the className of the body element
    //   document.body.className = "karaoke";
    //   // Clean up the className when the component unmounts
    //   return () => {
    //     document.body.className = "";
    //   };
    // }
    if (karaokePathList.includes(pathname)) {
      // Check if body has a class attribute
      if (document.body.classList) {
        // If body has class attribute, append "karaoke" class
        document.body.classList.add('karaoke');
      } else {
        // If body does not have class attribute, set class attribute to "karaoke"
        document.body.className += ' karaoke';
      }

      // Clean up the className when the component unmounts
      return () => {
        if (document.body.classList) {
          // If body has classList, remove "karaoke" class
          document.body.classList.remove('karaoke');
        } else {
          // If body does not have classList, remove "karaoke" class from className
          document.body.className = document.body.className.replace(
            'karaoke',
            ''
          );
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, siteType]);

  useEffect(() => {
    let siteData = localStorage.getItem('siteType');
    if (siteType) {
      if (siteType === 'tamil') {
        localStorage.setItem('siteType', 'tamil');
        dispatch(setSiteType({ siteType: 'tamil' }));
      }
      if (siteType === 'bharat') {
        localStorage.setItem('siteType', 'bharat');
        dispatch(setSiteType({ siteType: 'bharat' }));
      }
    } else if (siteData) {
      dispatch(setSiteType({ siteType: siteData }));
    } else {
      localStorage.setItem('siteType', 'bharat');
      dispatch(setSiteType({ siteType: 'bharat' }));
    }

    getAvatarListDetails();
    if (isLoggedIn) {
      getUserDetails(userDetails,dispatch,toggleLoader);
    }
    if (!hash) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, siteType]);

  const getAvatarListDetails = async () => {
    toggleLoader(true);
    try {
      const res = await getAvatarList();
      if (res.data && res.data.succeeded) {
        let data =
          res.data &&
          res.data.data &&
          Array.isArray(res.data.data) &&
          res.data.data;
        dispatch(setAvatarList(data));
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };


  if (switchLayout) {
    return (
      <>
        <main role="main">
          <Outlet />
        </main>
      </>
    );
  } else {
    return (
      <>
        {(isMobile && isHeaderFooterNotVisible) ||
        pathname === addPathSeparators('/connection_loss') ? (
          <main role="main">
            <Outlet />
          </main>
        ) : isMobile && onlyFooter ? (
          <>
            <main role="main">
              <Outlet />
            </main>
            <Footer />
          </>
        ) : (
          <>
            <Header />
            <main role="main">
              <Outlet />
            </main>
            <Footer />
          </>
        )}
      </>
    );
  }
}

export default PublicLayout;
