import toast from "react-hot-toast";
// import gluedin from 'gluedin';
import moment from "moment";
import axios from "axios";
// import useTranslation from '../hooks/use-translation';
import { isClaimWindowOpen } from "../../services";
import { store } from "../../store";
import { setClaimFormWindowStatus } from "../../store/slices/common-slice";
// import useTranslation from '../hooks/use-translation';
// import { isClaimWindowOpen } from '../../services';

//limit the input field
export const handleInputLimitedLength = (event, minLength, maxLength) => {
  const inputValue = event.target.value;
  // Limit the input value to the maximum length
  let limitedValue = inputValue.slice(0, maxLength);
  // Enforce minimum length
  if (limitedValue.length < minLength) {
    limitedValue = inputValue.slice(0, minLength);
  }
  // Update the input value with the limited value
  event.target.value = limitedValue;
};

// Mobile number validation
export const handleKeyPressMobileNumber = (event) => {
  const currentValue = event.target.value;

  // Remove any non-digit characters and limit the value to a maximum of 10 digits
  const filteredValue = currentValue.replace(/\D/g, "").slice(0, 10);

  // Check if the filtered value is '000' and handle accordingly
  if (/^0{3}$/.test(filteredValue)) {
    event.preventDefault();
    console.error("Invalid mobile number");
    return;
  }

  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleKeyPressPincode = (event) => {
  const currentValue = event.target.value;
  // Remove any non-digit characters and limit the value to a maximum of 6 digits
  const filteredValue = currentValue.replace(/\D/g, "").slice(0, 6);
  if (/^0+$/.test(filteredValue)) {
    event.preventDefault();
    console.error("In valid pincode");
    return;
  }

  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleKeyPressName = (event) => {
  const inputValue = event.target.value;
  const filteredValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, "").slice(0, 30);
  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleErrorMessages = (res, setFieldError, key) => {
  let message = res.data && res.data.message;
  setFieldError(key, message);
};

export const showApiErrors = (res) => {
  let message = res.data && res.data.message;
  toast.error(message, { id: "showApiErrors" });
};

export const returnMobileNumber = (data) => {
  if (data.alternateMobile) {
    return "+91 - " + data.mobileNo + ", +91 - " + data.alternateMobile;
  } else {
    return "+91 - " + data.mobileNo;
  }
};

// const handleShareEvent = async (videoId, shareElement) => {
//   const activityTimelineModuleObj = await new gluedin.GluedInActivityTimeline();
//   activityTimelineModuleObj
//     .activityTimelineShare({ assetId: videoId })
//     .then((response) => {
//       if (response && response.status === 200) {
//         let shareNumber = convertKToNumeric(shareElement?.innerHTML);
//         shareNumber++;
//         let shareCount = formatLargeNumber(shareNumber);
//         shareElement.innerHTML = shareCount;
//       }
//     });
// };

async function downloadImageAsBlob(imageUrl) {
  try {
    // Make a request for the image with responseType set to 'blob'
    const response = await axios.get(imageUrl, {
      responseType: "blob",
    });
    // The response data is now a Blob object
    const imageBlob = response.data;
    return imageBlob;
  } catch (error) {
    console.error("Error downloading image:", error);
  }
}

export const handleShare = async (shareContent) => {
  if (navigator.share) {
    let filesArray = "";
    try {
      if (shareContent.image) {
        let blob = await downloadImageAsBlob(shareContent.image);
        filesArray = blob && [
          new File([blob], "image.png", { type: "image/png" }),
        ];
      }
    } catch (error) {}
    const shareData = {
      title: shareContent && shareContent.title ? shareContent.title : "",
      text: shareContent && shareContent.text ? shareContent.text : "",
    };
    if (shareContent.image && shareContent.image !== null && filesArray) {
      shareData.files = filesArray;
    }

    if (shareContent.url) {
      shareData.url = shareContent.url;
    }
    navigator
      .share(shareData)
      .then(() => {
        if (shareContent.action) {
          shareContent.increaseShareCount();
        }
      })
      .catch((error) => console.log("Error sharing", error));
  } else {
    console.error("Browser doesn't support Web Share API");
  }
};

export const formatLargeNumber = (count) => {
  if (count < 1000) {
    return count.toString();
  } else if (count < 1000000) {
    return (count / 1000).toFixed(1) + "K";
  } else {
    return (count / 1000000).toFixed(1) + "M";
  }
};

export const convertKToNumeric = (kValue) => {
  if (typeof kValue === "string" && kValue.match(/(\d+(\.\d+)?)(K|M|B)?/)) {
    let match = kValue.match(/(\d+(\.\d+)?)(K|M|B)?/);
    let numericValue = parseFloat(match[1]);
    let modifier = match[3];
    if (modifier) {
      if (modifier === "K") {
        numericValue *= 1000;
      } else if (modifier === "M") {
        numericValue *= 1000000;
      } else if (modifier === "B") {
        numericValue *= 1000000000;
      }
    }
    return numericValue;
  }
  return null; // Return null for invalid input
};

function normalizePath(path) {
  // Remove any existing double slashes
  path = path.replace(/\/\//g, "/");
  // Add a single slash if not present
  if (path.charAt(0) !== "/") {
    path = "/" + path;
  }
  return path;
}

// Define a function to add Tamil separators conditionally
export const addPathSeparators = (path) => {
  // Your condition to determine if Tamil separators should be added
  // let siteType = localStorage.getItem("siteType");
  let typeOfSite="";

    if(localStorage.getItem("siteType"))
      {
        typeOfSite = localStorage.getItem("siteType", typeOfSite);
      }else{
        typeOfSite = window.location.pathname.includes("/tamil")
        ? "tamil"
        : "bharat";
      }

  if (typeOfSite) {
    localStorage.setItem("siteType", typeOfSite);
    //Add Tamil separators to the path
    return normalizePath(`/${typeOfSite}${path}`);
  } else {
    localStorage.setItem("siteType", typeOfSite);
    return normalizePath(`/${path}`);
  }
};

export const isTamil = () => {
  let siteType = localStorage.getItem("siteType");
  let typeOfSite =
    window.location.pathname.includes("/tamil") || siteType === "tamil"
      ? "tamil"
      : "bharat";
  if (typeOfSite === "tamil") {
    return true;
  } else {
    return false;
  }
};

export const isVideoNew = (createdTimestamp) => {
  let days = 7;
  const timestampDifference = days * 60 * 1000 * 60 * 24;
  if (Date.now() - createdTimestamp <= timestampDifference) return true;
  else return false;
};

export const getTimeDifference = (notificationDate) => {
  const currentTime = moment(); // Current time
  const notificationTime = moment(notificationDate); // Notification time
  // Calculate the difference in time
  const duration = moment.duration(currentTime.diff(notificationTime));
  // Get the duration in days, hours, minutes, and seconds
  const days = duration.asDays();
  const hours = duration.asHours();
  const minutes = duration.asMinutes();
  const seconds = duration.asSeconds();
  // Determine the appropriate time unit and value
  // if (days >= 1) {
  //   return `${Math.floor(days)} day(s) ago`;
  // } else if (hours >= 1) {
  //   return `${Math.floor(hours)} hour(s) ago`;
  // } else if (minutes >= 1) {
  //   return `${Math.floor(minutes)} minute(s) ago`;
  // } else {
  //   return `${Math.floor(seconds)} second(s) ago`;
  // }
  if (days >= 1) {
    return `${Math.floor(days)} d`;
  } else if (hours >= 1) {
    return `${Math.floor(hours)} h`;
  } else if (minutes >= 1) {
    return `${Math.floor(minutes)} m`;
  } else {
    return `${Math.floor(seconds)} sec`;
  }
};

export const getBitlyLink = (typeOfSection, vedioType) => {
  let siteType = localStorage.getItem("siteType");
  let siteValue = siteType === "tamil" ? "tn" : "bh";
  let environment = process.env.REACT_APP_ENV;

  let bitlyLink = `https://cokeurl.com/${environment}/${siteValue}/${typeOfSection}/*${vedioType}`;
  return bitlyLink;
};

export const getRewardType = (rewardTitle) => {
  const rewardType = [
    { label: "Airpods", value: "Airpods" },
    { label: "iPhone", value: "iPhone" },
    { label: "Lollapalooza", value: "Lollapalooza" },
    { label: "Tomorrowland", value: "Tomorrowland" },
    { label: "TicketsCSKVIPTickets", value: "CSK VIP Tickets" },
    { label: "CSKMatchTickets", value: "CSK Match Tickets" },
  ];
  let rewardValue = rewardType.find((el) => el.value.includes(rewardTitle));
  return rewardValue;
};

export const getCDSPixelId = async () => {
  //cookieStore doesn't work for safari and firefox
  try {
    const fetchPixelId = await window.cookieStore.get("__cds_pixel_id");
    if (fetchPixelId) {
      return fetchPixelId?.value;
    } else {
      return "";
    }
  } catch (error) {
    console.log("pixel error:", error);
  }

  // if (document?.cookie) {
  //   const fetchPixelId =
  //     document &&
  //     document?.cookie
  //       ?.split(";")
  //       .find((row) => row.startsWith("__cds_pixel_id="))

  //   //const hfSessionKey = fetchPixelId['value'];
  //   return fetchPixelId;
  // }
};

export const numberTotimeFormateConversion = (time) => {
  if (time) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return "00:00:00";
  }
};

export const audioMixerPlay = (backgroundSong, recordedSong) => {
  const url = URL.createObjectURL(recordedSong);
  // Create audio element for recorded audio
  const recordedAudio = new Audio(url);
  // Create audio element for background music
  const backgroundAudio = new Audio(backgroundSong);
  backgroundAudio.loop = true; // Loop the background music
  // Play the recorded audio
  recordedAudio.play();
  backgroundAudio.play();
  // When the recorded audio ends, pause the background music
  recordedAudio.addEventListener("ended", () => {
    backgroundAudio.pause();
  });
  // When the background music ends, pause the recorded audio
  backgroundAudio.addEventListener("ended", () => {
    recordedAudio.pause();
  });
  // Ensure that the background music stops when the recorded audio ends
  recordedAudio.addEventListener("pause", () => {
    backgroundAudio.pause();
  });
};

export const removeSpanFromString = (string) => {
  if (string) {
    const matchResult = string && string?.match(/<span>(.*?)<\/span>/);
    const filterString = matchResult ? matchResult[1] : string;
    return filterString;
  } else {
    return string;
  }
};

/**
 * @method isBlob
 * @description return boolean (T/F) if passed param is a blob object
 * @param {*} b
 * @returns
 */
export const isBlob = (b) => b instanceof Blob;

export function isGetUserMediaSupported() {
  return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
}
export const getlanguageId = (currentLanguage) => {
  let languageId = 1;
  if (currentLanguage === "hi" || currentLanguage === "ta") {
    languageId = 2;
  } else {
    languageId = 1;
  }
  return languageId;
};

export const CounterFloodLight = (ids) => {
  let siteType = localStorage.getItem("siteType");
  var sendToId;
  if (siteType === "tamil") {
    sendToId = ids[1];
  } else {
    sendToId = ids[0];
  }
  window.gtag("event", "conversion", {
    allow_custom_scripts: true,
    send_to: `${sendToId}`,
  });
};

export const MetaPixel = (BharatEvent, TamilEvent) => {
  let siteType = localStorage.getItem("siteType");
  if (siteType === "bharat") {
    window.fbq("track", BharatEvent);
  } else {
    window.fbq("track", TamilEvent);
  }
};

export const updateTagName = (tagData) => {
  // Select the meta description tag
  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  document.title = tagData.title;
  // Update the content attribute of the meta description tag based on the condition
  metaDescriptionTag.setAttribute("content", tagData.description);
  const canonicalLink = document.getElementById("canonicalLink");
  canonicalLink.href = window.location.href;
};

export let isOldKaraoke = process.env.REACT_APP_OLD_KAROKE_ENBLE
  ? process.env.REACT_APP_OLD_KAROKE_ENBLE
  : true;
export const manageKaraokePath = () => {
  // Check if the current URL is already the target URL
  // if (window.location.pathname === "/karaoke") {
  //   return "#";
  // }

  if (isOldKaraoke === "true") {
    // Redirect to /karaoke if isOldKaraoke is true
    return "/karaoke/";
  } else if (isOldKaraoke === "false") {
    // Check if the current URL is already /KaraokesongList
    // if (window.location.pathname === "/KaraokesongList") {
    //   return "#";
    // }

    // Add path separators to the given pathName if isOldKaraoke is false
    let newPath = addPathSeparators("/KaraokesongList");
    // Return the new path
    return newPath;
  }
};

export const isClaimFormWindowOpen = (
  rewardId,
  languageId,
  isIdRequired,
  isPassportRequired,
  matchingValue
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const reqData = {
        rewardId: rewardId,
        languageId: languageId,
      };

      const res = await isClaimWindowOpen(reqData);
      if (res.status === 200) {
        const canClaim = res?.data?.data?.canClaim;
        store.dispatch(setClaimFormWindowStatus(canClaim));
        resolve(res?.data?.data?.canClaim);
      } else {
        console.error(
          "Error occurred while checking claim window. Response status:",
          res.status
        );
        resolve(false);
      }
    } catch (err) {
      console.error("Error occurred while checking claim window:", err);
      resolve(false);
    }
  });
};

export const handleCLaimWindow = async (data, langId) => {
  try {
    let claimableRewardId = data?.rewardId !== 1 ? data?.rewardId : null;
    if (claimableRewardId) {
      const result = await isClaimFormWindowOpen(claimableRewardId, langId);
      store.dispatch(setClaimFormWindowStatus(result));
    }
  } catch (error) {
    console.error("Error occurred while handling claim window:", error);
  }
};
export const handleCopy = (labelRef) => {
  if (labelRef.current) {
    const textToCopy = labelRef.current.textContent;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        //toast.success('Copied to clipboard!');
      })
      .catch((error) => {
        //toast.error('Unable to copy to clipboard. Please try again.');
      });
  }
};

export const renderCopyIcon = (labelRef) => {
  return (
    <span onClick={() => handleCopy(labelRef)} style={{ cursor: "pointer" }}>
      <img
        className="promo-icon"
        src="/assets/img/icons/copies.svg"
        alt="copy icon"
      />
    </span>
  );
};

export const isTamilCampaignEnded = () => {
  const currentDate = new Date();
  const targetDate = new Date("2024-04-28T00:00:00");
  const isEnded = currentDate >= targetDate;
  return isEnded;
};

export const isBharatCampaignEnded = () => {
  const currentDate = new Date();
  const targetDate = new Date("2024-06-26T00:00:00");
  const isEnded = currentDate >= targetDate;
  return isEnded;
};

export function getYoutubeThumbnail(url, quality) {
  if (url) {
    var video_id, result;
    if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
      video_id = result.pop();
    } else if ((result = url.match(/youtu.be\/(.{11})/))) {
      video_id = result.pop();
    }

    if (video_id) {
      if (typeof quality === "undefined") {
        quality = "high";
      }

      var quality_key = "maxresdefault"; // Max quality
      if (quality === "low") {
        quality_key = "sddefault";
      } else if (quality === "medium") {
        quality_key = "mqdefault";
      } else if (quality === "high") {
        quality_key = "hqdefault";
      }

      return (
        "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg"
      );
    }
  }
  return false;
}
